<template>
    <div class="date-time-edit">
        <div>
            <v-label>{{ t('messages.resources.publishFrom', 'Publisert fra') }}</v-label>
            <div class="date-from">
                <div>
                    <v-menu
                        v-model="startPublish.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="290px"
                        offset-y
                        transition="scale-transition"
                    >
                        <template #activator="{ props: menuProps }">
                            <v-text-field
                                v-model="startPublish.date"
                                data-id="DateTextField"
                                readonly
                                v-bind="menuProps"
                                variant="outlined"
                                append-inner-icon="mdi-calendar"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="startPublishPicker"
                            data-id="DatePicker"
                            show-adjacent-months
                            @update:model-value="updateStartPublishTime"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div>
                    <v-text-field
                        v-model="startPublish.time"
                        type="time"
                        data-id="TimeTextField"
                        variant="outlined"
                        @update:model-value="updateStartPublishTime"
                    ></v-text-field>
                </div>
            </div>
            <v-label>{{ t('messages.resources.validFrom', 'Gjeldende fra') }}</v-label>
            <div class="valid-from">
                <div>
                    <v-menu
                        v-model="validFrom.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="290px"
                        offset-y
                        transition="scale-transition"
                    >
                        <template #activator="{ props: menuProps }">
                            <v-text-field
                                v-model="validFrom.date"
                                data-id="DateTextField"
                                readonly
                                v-bind="menuProps"
                                variant="outlined"
                                append-inner-icon="mdi-calendar"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="validFromPicker"
                            data-id="DatePicker"
                            show-adjacent-months
                            :allowed-dates="allowedValidFromDates"
                            @update:model-value="updateValidFromTime"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div>
                    <v-text-field
                        v-model="validFrom.time"
                        type="time"
                        data-id="TimeTextField"
                        variant="outlined"
                        @update:model-value="updateValidFromTime"
                    ></v-text-field>
                </div>
            </div>
            <v-label>{{ t('messages.resources.listEndPublish', 'Gjeldende til') }}</v-label>
            <div class="date-to">
                <div>
                    <v-menu
                        v-model="endPublish.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="290px"
                        offset-y
                        transition="scale-transition"
                    >
                        <template #activator="{ props: menuProps }">
                            <v-text-field
                                v-model="endPublish.date"
                                data-id="DateTextField"
                                readonly
                                v-bind="menuProps"
                                variant="outlined"
                                append-inner-icon="mdi-calendar"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endPublishPicker"
                            :allowed-dates="allowedPublishDates"
                            data-id="DatePicker"
                            show-adjacent-months
                            @update:model-value="updateEndPublishTime"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div>
                    <v-text-field
                        v-model="endPublish.time"
                        type="time"
                        data-id="TimeTextField"
                        variant="outlined"
                        @update:model-value="updateEndPublishTime"
                    ></v-text-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { ToLocalDate, FormatDateISO, DateTimeToUTC, DateToUTC } from '@/shared/DateFormatHelpers';

const { t } = useI18n();

const startTimeModel = defineModel<Date>('startPublish');
const endTimeModel = defineModel<Date>('endPublish');
const validFromModel = defineModel<Date>('validFrom');

const validFrom = ref({
    menu: false,
    menuTime: false,
    date: validFromModel.value ? ToLocalDate(validFromModel.value, 'ISOdate') : '',
    time: validFromModel.value ? ToLocalDate(validFromModel.value, 'time') : ''
});

const validFromPicker = computed<Date>({
    get: () => {
        return new Date(validFrom.value.date);
    },
    set: (value: Date) => {
        const d = value;
        validFrom.value.date = FormatDateISO(d);
    }
});

const startPublish = ref({
    menu: false,
    menuTime: false,
    date: startTimeModel.value ? ToLocalDate(startTimeModel.value, 'ISOdate') : '',
    time: startTimeModel.value ? ToLocalDate(startTimeModel.value, 'time') : ''
});

const startPublishPicker = computed<Date>({
    get: () => {
        return new Date(startPublish.value.date);
    },
    set: (value: Date) => {
        const d = value;
        startPublish.value.date = FormatDateISO(d);
    }
});

const endPublish = ref({
    menu: false,
    menuTime: false,
    date: endTimeModel.value ? ToLocalDate(endTimeModel.value, 'ISOdate') : '',
    time: endTimeModel.value ? ToLocalDate(endTimeModel.value, 'time') : ''
});

function allowedPublishDates(val: any) {
    return DateToUTC(val) >= DateToUTC(startPublish.value.date);
}

function allowedValidFromDates(val: any) {
    return DateToUTC(val) <= DateToUTC(endPublish.value.date);
}

const endPublishPicker = computed<Date>({
    get: () => {
        return new Date(endPublish.value.date);
    },
    set: (value: Date) => {
        const d = value;
        endPublish.value.date = FormatDateISO(d);
    }
});

function ensureValidEndStartTime(startTime: Date, endTime: Date) {
    // prevent possibility to have end publish time earlier than start publish time
    const isEndBeforeStart = startTime > endTime;

    if (isEndBeforeStart) {
        endPublish.value = {
            ...endPublish.value,
            time: startPublish.value.time,
            date: startPublish.value.date
        };
        endTimeModel.value = DateTimeToUTC(endPublish.value.date, endPublish.value.time);
    }
}

function ensureValidValidFromTime(validFromTime: Date, endTime: Date) {
    const isValidFromTime = validFromTime < endTime;

    if (!isValidFromTime) {
        const minuteInMilliseconds = 60000;
        const validFromDateTime = new Date(endTimeModel.value!.getTime() - minuteInMilliseconds);
        const time = ToLocalDate(validFromDateTime, 'time');

        validFrom.value = {
            ...validFrom.value,
            time: time,
            date: endPublish.value.date
        };
        validFromModel.value = DateTimeToUTC(validFrom.value.date, validFrom.value.time);
    }
}

function updateStartPublishTime() {
    const startPublishDateTime = DateTimeToUTC(startPublish.value.date, startPublish.value.time);
    startTimeModel.value = startPublishDateTime;

    ensureValidEndStartTime(startPublishDateTime, DateTimeToUTC(endPublish.value.date, endPublish.value.time));
}

function updateEndPublishTime() {
    const endPublishDateTime = DateTimeToUTC(endPublish.value.date, endPublish.value.time);
    endTimeModel.value = endPublishDateTime;

    ensureValidEndStartTime(DateTimeToUTC(startPublish.value.date, startPublish.value.time), endPublishDateTime);
    ensureValidValidFromTime(DateTimeToUTC(validFrom.value.date, validFrom.value.time), endPublishDateTime);
}

function updateValidFromTime() {
    const validFromDateTime = DateTimeToUTC(validFrom.value.date, validFrom.value.time);
    validFromModel.value = validFromDateTime;

    ensureValidValidFromTime(validFromDateTime, DateTimeToUTC(endPublish.value.date, endPublish.value.time));
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.valid-from,
.date-from,
.date-to {
    display: grid;
    grid-template-columns: calc(65% - 12px) calc(35% - 12px);
    column-gap: 24px;
}

.date-time-edit {
    :deep(input) {
        padding: 4px 16px;
        min-height: 40px;
    }

    :deep(.v-field) {
        background-color: $white;
    }

    :deep(.v-text-field .v-input__details) {
        display: none;
    }

    .v-label {
        font-size: 12px;
        margin-bottom: 8px;
        color: $black;
    }

    .date-from,
    .valid-from {
        margin-bottom: 12px;
    }
}
</style>
