<template>
    <div class="scope-container">
        <div class="scope-filters">
            <slot name="scope-select"></slot>
            <v-text-field
                v-model="searchModel"
                class="search"
                :placeholder="t('messages.resources.searchStop', 'Søke stopper')"
                append-inner-icon="mdi-magnify"
                color="primary"
                variant="outlined"
                data-id="SearchStops"
            ></v-text-field>
        </div>
        <div class="scope-content">
            <v-data-table
                v-model="selectedTableRows"
                :sort-by="sortBy"
                :headers="headers"
                :items="tableRows"
                :row-props="rowProps"
                :loading="loading"
                :loading-text="t('messages.resources.loadingStops', 'Laster stopper')"
                :search="searchModel"
                select-strategy="page"
                items-per-page="-1"
                no-data-text=""
                return-object
                show-select
                fixed-header
                height="calc(100vh - 255px)"
                @click:row="updateSelectedStops"
            >
                <template #bottom>
                    <div class="lines-total">{{ `${t('messages.resources.total', 'Total')}: ${tableRows.length}` }}</div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { StopReceiver } from '../../messagesModels';
import { Stop } from '@/Features/Stops/stopsModels';
import { ref, watch, computed, nextTick } from 'vue';
import { stopReceiversEqual } from '../../helpers';

const { t } = useI18n();

const stopsModel = defineModel<StopReceiver[]>('selectedStops');
const searchModel = defineModel<string>('stopsSearch');

interface Props {
    stops: Stop[];
    loading: boolean;
}

const props = defineProps<Props>();

interface TableRow {
    stopName: string;
    id: string;
    platform: string;
    data: StopReceiver;
}

const sortBy = ref<any[]>([{ key: 'id', order: 'asc' }]);
const headers = computed<any[]>(() => [
    {
        title: t('messages.resources.scopeLineName', 'Stop'),
        key: 'stopName',
        sortable: true,
        align: 'center'
    },
    {
        title: t('messages.resources.scopeLinePrivateCode', 'Platform'),
        key: 'platform',
        sortable: false
    },
    {
        title: t('messages.resources.scopeLinePrivateCode', 'Id'),
        key: 'id',
        sortable: false
    }
]);

const tableRows = ref<TableRow[]>([]);
const selectedTableRows = ref<TableRow[]>([]);

const isTableRowsLoading = ref(false);

const stopsArray = computed(() => props.stops);
watch(stopsArray, loadTableRows, {
    deep: true
});
const isModelUpdating = ref(false);
watch(stopsModel, onModelUpdating, {
    deep: true
});
const isTableRowsUpdating = ref(false);
watch(selectedTableRows, onTableRowsUpdating, {
    deep: true
});

loadTableRows(props.stops);

function loadTableRows(stops: Stop[]) {
    isTableRowsLoading.value = true;

    tableRows.value = stops.map(x => toStopReceiverTableRow(x));
    updateSelectedTableRows();
    nextTick(() => {
        isTableRowsLoading.value = false;
    });
}

function onTableRowsUpdating(newValue: TableRow[], oldValue: TableRow[]) {
    if (isModelUpdating.value) return;
    if (isTableRowsLoading.value) return;
    isTableRowsUpdating.value = true;
    updateModel(newValue, oldValue);
    nextTick(() => {
        isTableRowsUpdating.value = false;
    });
}

function onModelUpdating() {
    if (isTableRowsUpdating.value) return;
    isModelUpdating.value = true;
    updateSelectedTableRows();
    nextTick(() => {
        isModelUpdating.value = false;
    });
}

function updateSelectedTableRows() {
    selectedTableRows.value = tableRows.value.filter(x => isSelectedTableRow(x));
}

function isSelectedTableRow(tableRow: TableRow): boolean {
    return stopsModel.value?.some(x => stopReceiversEqual(x, tableRow.data)) ?? false;
}

function updateModel(newValue: TableRow[], oldValue: TableRow[]) {
    const addedValues = newValue.filter(x => !oldValue.some(y => stopReceiversEqual(x.data, y.data))).map(x => x.data);
    const removedValues = oldValue.filter(x => !newValue.some(y => stopReceiversEqual(x.data, y.data))).map(x => x.data);
    const modelValue = stopsModel.value ?? [];
    stopsModel.value = [...modelValue, ...addedValues].filter(x => !removedValues.some(y => stopReceiversEqual(x, y)));
}

function toStopReceiverTableRow(stop: Stop): TableRow {
    const stopReceiver = {
        id: stop.id,
        name: stop.name,
        fullName: stop.fullName,
        publicCode: stop.publicCode
    };

    return {
        stopName: stop.name,
        id: stop.id,
        platform: stop.publicCode,
        data: stopReceiver
    };
}

function updateSelectedStops(_, row: any) {
    const lineToRemove = selectedTableRows.value.find(x => stopReceiversEqual(x.data, row.item.data));
    if (lineToRemove) {
        selectedTableRows.value = selectedTableRows.value.filter(x => !stopReceiversEqual(x.data, lineToRemove.data));
    } else {
        selectedTableRows.value = [row.item, ...selectedTableRows.value];
    }
}

function rowProps(row: any) {
    const isSelected = selectedTableRows.value.find(x => row.item.data.id === x.data.id);
    if (isSelected) {
        return { class: 'selected' };
    }
    return {};
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.scope-filters {
    display: flex;
    column-gap: 20px;

    .search {
        max-width: 350px;
    }
}

.scope-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.v-table {
    :deep(.selected) {
        background-color: $selectedGreen;

        &:hover {
            background-color: $lightGreen;
        }

        .v-icon {
            color: $primary;
        }
    }
}

.lines-total {
    color: #a0a0a0;
    font-size: 14px;
    padding: 12px 0 0 12px;
}

@media screen and (max-width: 1038px) {
    :deep(.v-table__wrapper) {
        max-height: calc(100vh - 400px) !important;
    }
}
</style>
