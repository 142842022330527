<template>
    <div class="scope-container">
        <div class="scope-filters">
            <slot name="scope-select"></slot>
            <div>
                <v-menu
                    v-model="operatingDayMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                >
                    <template #activator="{ props: itemProps }">
                        <v-text-field
                            v-model="operatingDay"
                            :label="t('messages.resources.date', 'Dato')"
                            data-id="DateTextField"
                            append-inner-icon="mdi-calendar"
                            readonly
                            variant="outlined"
                            persistent-placeholder
                            v-bind="itemProps"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="operatingDayPicker"
                        data-id="DatePicker"
                        show-adjacent-months
                        @update:model-value="operatingDayMenu = false"
                    ></v-date-picker>
                </v-menu>
            </div>
            <div>
                <v-autocomplete
                    v-model="selectedLine"
                    :items="autocompleteLines"
                    :label="t('messages.resources.line', 'Linje')"
                    :placeholder="t('messages.resources.searchLine', 'Søke linjer')"
                    append-inner-icon="mdi-magnify"
                    return-object
                    color="primary"
                    variant="outlined"
                    persistent-placeholder
                    data-id="LineAutoComplete"
                >
                </v-autocomplete>
            </div>
            <div>
                <v-autocomplete
                    v-model="selectedVariant"
                    :items="autocompleteVariants"
                    :label="t('messages.resources.filterVariant', 'Kjøremønster')"
                    :placeholder="t('messages.resources.searchVariant', 'Søke kjøremønster')"
                    append-inner-icon="mdi-magnify"
                    return-object
                    color="primary"
                    variant="outlined"
                    persistent-placeholder
                    data-id="VariantAutoComplete"
                >
                </v-autocomplete>
            </div>
        </div>
        <div class="scope-content">
            <v-data-table
                v-model="selectedTableRows"
                :sort-by="sortBy"
                :headers="headers"
                :items="tableRows"
                :search="selectedVariant.value"
                :custom-filter="filterByVariant"
                :row-props="rowProps"
                :loading="loading"
                :loading-text="t('messages.resources.loadingDepartures', 'Laster avganger')"
                item-selectable="selectable"
                select-strategy="page"
                items-per-page="-1"
                no-data-text=""
                return-object
                show-select
                fixed-header
                height="calc(100vh - 255px)"
                @click:row="updateSelectedDepartures"
            >
                <template #bottom>
                    <div class="departures-total">{{ `${t('messages.resources.total', 'Total')}: ${tableRows.length}` }}</div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { toLineAutocompleteItem } from '@/Features/Lines/lineHelpers';
import { Line } from '@/Features/Lines/linesModels';
import { FormatDateISO, ToLocalDate } from '@/shared/DateFormatHelpers';
import { AutocompleteItem } from '@/shared/models';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DepartureReceiver, ServiceJourney, TripStatus } from '../../messagesModels';
import { departureReceiversEqual } from '../../helpers';
import { nextTick } from 'vue';
import _ from 'lodash';
import i18n from '@/plugins/i18n';

const { t } = useI18n();

const model = defineModel<DepartureReceiver[]>();

interface Props {
    lines: Line[];
    serviceJourneys: ServiceJourney[];
    loading: boolean;
}

interface TableRow {
    departureTime: string;
    arrivalTime: string;
    arrivalAt: Date;
    departure: string;
    destination: string;
    status: string;
    selectable: boolean;
    data: DepartureReceiver;
}

const sortBy = ref<any[]>([{ key: 'departureTime', order: 'asc' }]);
const headers = computed<any[]>(() => [
    {
        title: t('messages.resources.departureAt', 'Avgangstid'),
        key: 'departureTime',
        sortable: true,
        sortRaw(a: TableRow, b: TableRow) {
            return sortDateTimeTableRow(a.data.departureTime, b.data.departureTime);
        },
        width: '140px',
        align: 'center'
    },
    {
        title: t('messages.resources.arrivalAt', 'Ankomsttid'),
        key: 'arrivalTime',
        sortable: true,
        sortRaw(a: TableRow, b: TableRow) {
            return sortDateTimeTableRow(a.arrivalAt, b.arrivalAt);
        },
        width: '140px',
        align: 'center'
    },
    {
        title: t('messages.resources.departureStop', 'Avgangsholdeplass'),
        key: 'departure',
        sortable: true
    },
    {
        title: t('messages.resources.destinationStop', 'Endeholdeplass'),
        key: 'destination',
        sortable: true
    },
    {
        title: t('messages.resources.serviceJourneyStatus', 'Status'),
        key: 'status',
        sortable: false
    }
]);

const props = defineProps<Props>();
const emit = defineEmits<{
    (e: 'updateOperatingDay', operatingDay: string);
    (e: 'updateLine', line: Line | null);
}>();

const operatingDayMenu = ref(false);
const operatingDay = ref(FormatDateISO(new Date()));
const operatingDayPicker = computed<Date>({
    get: () => {
        return new Date(operatingDay.value);
    },
    set: (value: Date) => {
        const d = value;
        operatingDay.value = FormatDateISO(d);
    }
});

const tableRows = ref<TableRow[]>([]);
const selectedTableRows = ref<TableRow[]>([]);

const selectedLine = ref<AutocompleteItem<Line> | null>(null);
const autocompleteLines = computed<AutocompleteItem<Line>[]>(() => props.lines.map(x => toLineAutocompleteItem(x)));

const allVariantValue = i18n.global.t('shared.resources.common.all'); // A hack for translations to work in setup
const allVariant = { title: allVariantValue, value: allVariantValue, data: allVariantValue };
const selectedVariant = ref<AutocompleteItem<string>>(allVariant);
const autocompleteVariants = computed<AutocompleteItem<string>[]>(() => {
    const items = tableRows.value.map(x => toVariantAutocompleteItem(x));
    return [
        allVariant,
        ..._.chain(items)
            .uniqWith(_.isEqual)
            .sortBy(x => x.value)
            .value()
    ];
});

watch(operatingDay, updateOperatingDay);
watch(selectedLine, updateLine);

const isTableRowsLoading = ref(false);
watch(() => props.serviceJourneys, loadTableRows);
const isModelUpdating = ref(false);
watch(model, onModelUpdating, {
    deep: true
});
const isTableRowsUpdating = ref(false);
watch(selectedTableRows, onTableRowsUpdating, {
    deep: true
});

loadTableRows(props.serviceJourneys);

function onModelUpdating() {
    if (isTableRowsUpdating.value) return;
    isModelUpdating.value = true;
    updateSelectedTableRows();
    nextTick(() => {
        isModelUpdating.value = false;
    });
}

function onTableRowsUpdating(newValue: TableRow[], oldValue: TableRow[]) {
    if (isModelUpdating.value) return;
    if (isTableRowsLoading.value) return;
    isTableRowsUpdating.value = true;
    updateModel(newValue, oldValue);
    nextTick(() => {
        isTableRowsUpdating.value = false;
    });
}

function loadTableRows(serviceJourneys: ServiceJourney[]) {
    isTableRowsLoading.value = true;
    selectedVariant.value = allVariant;
    tableRows.value = serviceJourneys.map(x => toDepartureReceiverTableRow(x));
    updateSelectedTableRows();
    nextTick(() => {
        isTableRowsLoading.value = false;
        const pastRows = tableRows.value.filter(x => x.data.departureTime < new Date());

        if (pastRows.length !== tableRows.value.length) {
            document.documentElement.getElementsByClassName('v-table__wrapper')[0].scrollTo({
                top: pastRows.length * 52,
                behavior: 'smooth'
            });
        }
    });
}

function updateSelectedTableRows() {
    selectedTableRows.value = tableRows.value.filter(x => isSelectedTableRow(x));
}

function updateModel(newValue: TableRow[], oldValue: TableRow[]) {
    const addedValues = newValue.filter(x => !oldValue.some(y => departureReceiversEqual(x.data, y.data))).map(x => x.data);
    const removedValues = oldValue.filter(x => !newValue.some(y => departureReceiversEqual(x.data, y.data))).map(x => x.data);
    const modelValue = model.value ?? [];
    model.value = [...modelValue, ...addedValues].filter(x => !removedValues.some(y => departureReceiversEqual(x, y)));
}

function updateOperatingDay(newValue: string) {
    emit('updateOperatingDay', newValue);
}

function updateLine(newValue: AutocompleteItem<Line> | null) {
    const line = newValue ? newValue.data : null;
    emit('updateLine', line);
}

function filterByVariant(value: string, query: string, item?: any) {
    if (allVariantValue == query) return true;
    const tableRow = item?.value as TableRow;
    if (tableRow) {
        const variant = toVariantValue(tableRow);
        return variant == query;
    }
    return false;
}

function toDepartureReceiverTableRow(serviceJourney: ServiceJourney): TableRow {
    const time = ToLocalDate(serviceJourney.departureAt, 'time');
    const date = ToLocalDate(serviceJourney.departureAt, 'date');
    const name = `Linje ${serviceJourney.lineName} (${serviceJourney.departure} - ${serviceJourney.destination}) ${time}/(${date})`;

    const departureReceiver = {
        id: serviceJourney.tripId,
        name: name,
        departureTime: serviceJourney.departureAt,
        arrivalTime: serviceJourney.arrivalAt,
        operatingDay: serviceJourney.operatingDay,
        departure: serviceJourney.departure,
        destination: serviceJourney.destination,
        lineName: serviceJourney.lineName,
        companyNumber: serviceJourney.companyNumber
    };

    return {
        departureTime: formatTime(serviceJourney.departureAt),
        arrivalTime: formatTime(serviceJourney.arrivalAt),
        arrivalAt: serviceJourney.arrivalAt,
        departure: serviceJourney.departure,
        destination: serviceJourney.destination,
        status: formatStatus(serviceJourney.status),
        selectable: serviceJourney.status != TripStatus.Cancelled,
        data: departureReceiver
    };
}

function toVariantAutocompleteItem(tableRow: TableRow) {
    const value = toVariantValue(tableRow);
    return { title: value, value: value, data: value };
}

function toVariantValue(tableRow: TableRow) {
    return `${tableRow.departure} - ${tableRow.destination}`;
}

function isSelectedTableRow(tableRow: TableRow): boolean {
    return model.value?.some(x => departureReceiversEqual(x, tableRow.data)) ?? false;
}

function sortDateTimeTableRow(aDateTime: Date, bDateTime: Date) {
    if (aDateTime < bDateTime) return -1;
    if (aDateTime > bDateTime) return 1;
    return 0;
}

function formatTime(date?: Date): string {
    if (!date) {
        return '';
    } else {
        return ToLocalDate(date, 'time');
    }
}

function formatStatus(status: TripStatus) {
    switch (status) {
        case TripStatus.Cancelled:
            return t('messages.resources.tripStatusCancelled', 'Innstilt');
        default:
            return '';
    }
}

function updateSelectedDepartures(_, row: any) {
    if (!row.item.selectable) return;

    const departureToRemove = selectedTableRows.value.find(x => departureReceiversEqual(x.data, row.item.data));
    if (departureToRemove) {
        selectedTableRows.value = selectedTableRows.value.filter(x => !departureReceiversEqual(x.data, departureToRemove.data));
    } else {
        selectedTableRows.value = [row.item, ...selectedTableRows.value];
    }
}

function rowProps(row: any) {
    if (!row.item.selectable) {
        return { class: 'cancelled' };
    }
    const isSelected = selectedTableRows.value.find(x => row.item.data.id === x.data.id);
    if (isSelected) {
        return { class: 'selected' };
    }
    const hasPassed = row.item.data.departureTime < new Date();
    if (hasPassed) {
        return { class: 'passed' };
    }

    return {};
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.scope-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.scope-filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
}

.v-table {
    :deep(.passed) {
        color: $inputOutlineGrey;
        background-color: $lightGrey;
    }

    :deep(.cancelled) {
        background-color: $disabledRed;
        cursor: default;

        &:hover {
            background-color: $disabledRed;
        }
    }

    :deep(.selected) {
        background-color: $selectedGreen;

        &:hover {
            background-color: $lightGreen;
        }

        .v-icon {
            color: $primary;
        }
    }
}

.departures-total {
    color: #a0a0a0;
    font-size: 14px;
    padding: 12px 0 0 12px;
}

@media screen and (max-width: 1038px) {
    :deep(.v-table__wrapper) {
        max-height: calc(100vh - 400px) !important;
    }
}
</style>
